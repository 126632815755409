.search
  &-form
    width: 260px
    padding: 8px 20px
    border-radius: 4px
  &-icon
    transform: translateX(-8px)
    cursor: pointer
  &-field
    width: calc(100% - 8px)
    color: $dark
    font-size: 1em
    font-weight: 200
    background-color: transparent
    outline: none !important
    border: none !important
    +placeholder
      color: $grey
