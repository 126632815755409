#hero
  display: flex
  justify-content: center
  align-items: center
  height: 100vh
  background: #444 url('../../assets/img/hero.webp') center top no-repeat
  background-blend-mode: overlay
  background-size: cover
  background-attachment: fixed
  +v-sm
    padding: 10vh 0
    height: auto
  +v-xs
    padding: 20vh 0 10vh

#cta
  background: #444 url('../../assets/img/cta.webp') center center no-repeat
  background-blend-mode: overlay
  background-size: cover
  background-attachment: fixed
  +v-sm
    padding: 10vh 0
    height: auto
  +v-xs
    padding: 20vh 0 10vh

#thankyou
  background: #444 url('../../assets/img/thankyou.webp') center top no-repeat
  background-blend-mode: overlay
  background-size: 100%
  background-attachment: fixed
  +v-sm
    padding: 10vh 0
    height: auto
    background-size: cover !important
  +v-xs
    padding: 12vh 0 0 !important

#error
  background: #444 url('../../assets/img/not.webp') center top no-repeat
  background-blend-mode: overlay
  background-size: 100%
  background-attachment: fixed
  +v-sm
    padding: 10vh 0
    height: auto
    background-size: cover !important
  +v-xs
    padding: 12vh 0 0 !important

#cases
  background: #444 url('../../assets/img/cases.webp') center top no-repeat
  background-blend-mode: overlay
  background-size: 100%
  background-attachment: fixed
  +v-sm
    padding: 10vh 0
    height: auto
    background-size: cover !important
  +v-xs
    padding: 12vh 0 0 !important

#adv
  background: #444 url('../../assets/img/adv.webp') center top no-repeat
  background-blend-mode: overlay
  background-size: 100%
  background-attachment: fixed
  +v-sm
    padding: 10vh 0
    height: auto
    background-size: cover !important
  +v-xs
    padding: 12vh 0 0 !important

#volunteer
  background: #444 url('../../assets/img/volunteer.webp') center top no-repeat
  background-blend-mode: overlay
  background-size: 100%
  background-attachment: fixed
  +v-sm
    padding: 10vh 0
    height: auto
    background-size: cover !important
  +v-xs
    padding: 12vh 0 0 !important

#zayvka
  background: #444 url('../../assets/img/zayav.webp') center center no-repeat
  background-blend-mode: overlay
  background-size: 100%
  background-attachment: fixed
  +v-sm
    padding: 10vh 0
    height: auto
    background-size: cover !important
  +v-xs
    padding: 10vh 0

#ticket
  background: #444 url('../../assets/img/ticket.webp') center center no-repeat
  background-blend-mode: overlay
  background-size: 100%
  background-attachment: fixed
  +v-sm
    padding: 10vh 0
    height: auto
    background-size: cover !important
  +v-xs
    padding: 10vh 0

#ivent
  background: #444 url('../../assets/img/bgi1.webp') center 30px no-repeat
  background-blend-mode: overlay
  background-size: 100%
  background-attachment: fixed
  +v-sm
    padding: 10vh 0
    height: auto
    background-size: cover !important
  +v-xs
    padding: 12vh 0 0 !important

#beav
  background: url('../../assets/img/beav.webp') center center no-repeat
  background-size: 100%
  background-attachment: fixed
  +v-sm
    padding: 10vh 0
    height: auto
    background-size: cover !important
  +v-xs
    padding: 10vh 0
