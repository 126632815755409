.title
  margin: 0
  padding: 0
  +v-xs
    font-size: 1.2em
  &-cta
    font-size: 4em
    font-weight: 200
  &-intro
    font-size: 1.85em
    font-family: $intro
    font-weight: 700
    line-height: 40px
    +v-xs
      font-size: 1.2em
      padding: 0 24px
      line-height: 32px
    &-sub
      font-size: 1.35em
      font-weight: 500
      +v-xs
        font-size: 1.15em
        padding: 0 24px
  &-section
    font-size: 1.5em
    font-family: $intro
    font-weight: 700
    line-height: 1.25
    letter-spacing: 3%
    +v-xs
      font-size: 1.2em
      line-height: 1.5
  &-icon
    font-size: 64px
  &-sub
    font-size: 1.35em
    font-family: $intro
    font-weight: 300
    line-height: 1.4
    text-transform: none
  &-mini
    font-size: 1em
    font-weight: 600
    line-height: 1.4
    text-transform: uppercase
  &-faq
    font-size: 1.2em
    font-family: $intro
    font-weight: 600
    line-height: 1.4

.text
  &-faq
    font-size: 1em
    font-weight: 400
    line-height: 1.25


.title-section
  &.w-50
    +v-xs
      width: 60% !important
      min-width: 60% !important
      max-width: 60% !important

#mission
  .mx-3
    p
      &.c-dark
        +v-xs
          margin-bottom: 40px
