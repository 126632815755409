.button
  display: inline-flex
  justify-content: center
  align-items: center
  height: 60px
  padding: 0 40px
  font-size: 1.05em
  font-weight: 600
  text-transform: uppercase
  border-radius: 4px
  transition: all ease-out .25s
  border: none
  outline: none !important
  &.small
    height: 50px
    padding: 0 32px
    font-size: .95em
    border-radius: 4px
    +v-xs
      font-size: 0.75em
      margin-left: auto
      margin-right: auto

  &.outline
    border: 2px solid $theme
    background-color: transparent
  +v-sm
    display: flex
    width: 60%
    margin: 24px auto
  +v-xs
    display: flex
    width: 100%
    margin: 24px 0
    padding: 0
    font-size: 0.82em
