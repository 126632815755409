html
  font-family: $basic
  font-weight: normal
  font-size: 1rem
  font-display: auto

body
  width: 100%
  max-width: 100% !important
  color: $dark
  font-size: 95%
  font-family: $basic
  font-weight: 400
  font-smooth: always
  line-height: 1.6
  -webkit-font-smoothing: antialised
  -moz-osx-font-smoothing: grayscale
  overflow-x: hidden
  background-color: $body

a, * a
  color: inherit
  text-decoration: none

.accordion
  background-color: transparent !important
  z-index: 0 !important
  .accordion-item
    border-radius: 0 !important
    &::focus
      border: none !important
      outline: none !important
  *
    background-color: transparent !important
    border-left: none !important
    border-right: none !important
  .accordion-button
    padding-right: 80px !important
    color: $dark !important
    font-size: .5em !important
    font-family: $intro
    line-height: 1.5
    background-color: transparent !important
    &::after
      margin-right: -60px !important
  .accordion-button:not(.collapsed)
    padding-right: 80px !important
    color: $dark !important
    font-size: .5em !important
    font-family: $intro
    line-height: 1.5
    background-color: transparent !important
    &::after
      transform: rotate(180deg) !important

footer
  .footer
    &-top
      border-top: 1px solid rgb(222, 226, 230)
      p
        +v-xs
          width: 86% !important
          min-width: 86% !important
          max-width: 86% !important

  *
    font-weight: 300
    +v-sm
      text-align: center
    +v-xs
      font-size: .97em
  p
    &.c-dark
      +v-xs
        width: 86% !important
        min-width: 86% !important
        max-width: 86% !important
        margin-left: auto
        margin-right: auto

p.c-dark,
h4.title-mini,
h3.title-sub
  +v-xs
    width: 86% !important
    min-width: 86% !important
    max-width: 86% !important
    margin-left: auto !important
    margin-right: auto !important

img
  +v-xs
    display: block
    width: 86% !important
    min-width: 86% !important
    max-width: 86% !important
    margin-left: auto !important
    margin-right: auto !important
    height: 300px !important




.m-40
  display: none
  +v-sm
    display: block
    margin-top: 40px
