header
  width: 100%
  height: 80px
  position: fixed
  z-index: 999 !important
  .mob-header
    +v-xs
      display: block !important

.home,
.page
  header
    +v-sm
      height: auto
      background: none !important
      position: absolute

.top
  &-nav
    height: 50px
    a
      margin: 0 16px
      color: $gold
      font-size: .87em
      font-weight: 400
      text-transform: uppercase
      text-decoration: none
      transition: all ease-out .3s
      &:hover
        color: $white
      +v-xs
        &:first-child
          display: none
        margin: 0 8px
.site-title
  a
    span
      font-size: 20px
      font-weight: 400

.burger
  display: none
  +v-sm
    position: fixed
    top: 30px
    right: 50px
    display: block
    span
      width: 24px
      height: 2px
      background-color: $dark
      transition: all ease-out .25s
      &.tt
        position: absolute
        left: 0
        top: 0
      &.mm
        position: absolute
        left: 0
        top: 8px
      &.bb
        position: absolute
        left: 0
        top: 16px
    &.open
      span
        width: 24px
        height: 2px
        background-color: $dark
        &.tt
          position: absolute
          left: 0
          top: 8px
          transform: rotate(45deg)
        &.mm
          width: 0
          position: absolute
          left: 12px
          top: 16px
          background-color: transparent
        &.bb
          position: absolute
          left: 0
          top: 8px
          transform: rotate(-45deg)
  +v-xs
    position: fixed
    top: 28px
    right: 56px
    display: block
