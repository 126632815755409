*
  font-family: $basic

@for $i from 0 through 300
  .w-#{$i}
    $width: $i * 1%
    width: $width !important
    min-width: $width !important
    max-width: $width !important
    display: block
    +v-sm
      width: 100% !important
      min-width: 100% !important
      max-width: 100% !important

@for $i from 0 through 1000
  .h-#{$i}
    $height: $i * 1px
    height: $height !important
    min-height: $height !important
    max-height: $height !important
    display: block

@for $i from 0 through 300
  .s-#{$i}
    $height: $i * 1px
    height: $height !important
    clear: both
    display: block

@for $i from 0 through 100
  .h-#{$i}-v
    $height: $i * 1vh
    height: $height !important
    clear: both
    display: block


.button
  +v-sm
    width: 70% !important
    min-width: 70% !important
    max-width: 70% !important

.site-title
  img
    +v-sm
      width: 32px !important
      height: 32px !important
      margin-top: 12px
    +v-xs
      width: 40px !important
      height: 40px !important
      margin-top: 12px
  span
    +v-sm
      display: inline-block
      font-size: 14px !important
      transform: translateY(4px)
    +v-xs
      display: none
  +v-xs
    text-align: center
    margin-left: auto
    margin-right: auto

#mission
  .mx-5
    +v-xs
      margin-left: 0!important
      margin-right: 0!important
  *
    +v-sm
      text-align: center !important
      margin-left: auto !important
      margin-right: auto !important
  img
    +v-sm
      margin-top: 24px
      margin-bottom: 24px

.bg-light,
#mission
  .c-dark
    &.w-70
      +v-sm
        width: 80% !important
        min-width: 80% !important
        max-width: 80% !important

#features
  .mx-5
    +v-xs
      margin-left: 0 !important
      margin-right: auto !important
  .title
    &-sub
      +v-xs
        font-size: 1em !important
        display: inline-block
  .c-dark
    &.w-70
      +v-xs
        width: 80% !important
        min-width: 80% !important
        max-width: 80% !important
        margin-bottom: -40px !important

#cta
  .c-white
    &.w-70
      +v-xs
        width: 86% !important
        min-width: 86% !important
        max-width: 86% !important
        margin-bottom: -20px !important


.title-section
  &.c-white
    &.w-80
      +v-xs
        width: 86% !important
        min-width: 86% !important
        max-width: 86% !important

.h-156
  +v-xs
    height: 80px !important
    min-height: 80px !important
    max-height: 80px !important


.accordion
  +v-xs
    margin-top: -40px !important
    margin-bottom: -24px !important

#faq
  .button
    &.small
      +v-xs
        text-align: center !important

.s-120,
.s-100,
.s-80,
.s-60
  +v-sm
    height: 40px !important

.mx-5
  *
    +v-sm
      text-align: center !important
      margin-left: 24px !important
      margin-right: 24px !important
      margin-bottom: 24px

.mx-5
  em.w-3
    +v-sm
      display: inline-block
      width: auto !important
      min-width: auto !important
      max-width: auto !important
      margin-left: 0 !important
      margin-right: 0 !important
      margin-bottom: 0
  .title-sub
    +v-sm
      display: inline-flex
      justify-content: center
      align-items: center
      transform: translateY(12px)
    +v-xs
      transform: translateY(0)

.title-sub.c-gold.flex.jcfs.aic
  +v-xs
    display: inline-block
    margin-left: -24px !important
    margin-top: 20px !important
    margin-bottom: -10px !important


.top-nav
  +v-sm
    transform: translateY(8px)
  +v-xs
    transform: translateY(0)


.ma
  margin-left: auto !important
  margin-right: auto !important
.mla
  margin-left: auto !important
.mra
  margin-right: auto !important

.obfc
  object-fit: cover

.wide
  +v-sm
    min-width: 90% !important
    margin-left: auto
    margin-right: auto
.aic
  align-items: center !important

.aifs
  align-items: flex-start !important

.aife
  align-items: flex-end !important

.jcc
  justify-content: center !important

.jcfs
  justify-content: flex-start !important

.jcfe
  justify-content: flex-end !important

.jcsa
  justify-content: space-around !important

.jcsb
  justify-content: space-between !important

.center
  justify-content: center !important

.tl
  text-align: left !important

.tr
  text-align: right !important
  +v-sm
    text-align: center !important

.tc
  text-align: center !important

.r
  &-16
    border-radius: 16px !important
  &-32
    border-radius: 32px !important
  &-24
    border-radius: 24px !important
  &-8
    border-radius: 8px !important
  &-4
    border-radius: 4px !important

.rowrap
  flex-flow: row wrap

.oh
  overflow: hidden

.flex
  display: flex

.upp
  text-transform: uppercase

lh
  &-1
    line-height: 1 !important
  &-105
    line-height: 1.05 !important
  &-125
    line-height: 1.25 !important
  &-15
    line-height: 1.5 !important
  &-175
    line-height: 1.75 !important
  &-2
    line-height: 2 !important
