=v-xl
  @media only screen and (min-width: 1441px)
    @content
=v-lg
  @media only screen and (max-width: 1440px)
    @content
=v-md
  @media only screen and (max-width: 1024px)
    @content
=v-sm
  @media only screen and (max-width: 768px)
    @content
=v-xs
  @media only screen and (max-width: 480px)
    @content